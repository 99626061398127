import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './font.css';
import './assets/reset.css';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';

const profile = process.env.REACT_APP_PROFILE as string;

const firebaseConfig = {
  apiKey: 'AIzaSyDY2YC48Intl7foI4H67EzIdwmZqrrVQk8',
  authDomain: 'canofy-brand-page.firebaseapp.com',
  projectId: 'canofy-brand-page',
  storageBucket: 'canofy-brand-page.appspot.com',
  messagingSenderId: '341230624227',
  appId: '1:341230624227:web:eb92e620f912af965394d6',
  measurementId: 'G-8HWV3DMD5J',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, profile === 'product' ? true : false);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
