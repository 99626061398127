import gsap from 'gsap';
import style from './style.module.css';
import { useEffect, useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useMediaQuery } from 'react-responsive';
import useAnalyticsLogEvent from 'hooks/useAnalyticsLogEvent';

const Landing = () => {
  const [scrollY, setScrollY] = useState<number>(0);
  const [sectionTextInActive, setSectionTextInActive] =
    useState<boolean>(false);

  const [scrollDirection, setScrollDirection] = useState<
    'up' | 'down' | undefined
  >(undefined);

  const textRef = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const textRef4 = useRef(null);
  const textRef5 = useRef(null);

  const isPC = useMediaQuery({
    query: '(min-width: 1025px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 769px) and (max-width: 1024px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  const { pages } = useAnalyticsLogEvent();
  useEffect(() => {
    const pageParam = {
      page_title: 'Landing',
      page_location: document.location.host,
      page_path: document.location.pathname,
    };
    pages(pageParam);
  }, []);

  useEffect(() => {
    let lastScrollY = 0;
    const fnScroll = () => {
      setScrollY(window.scrollY);

      let currentScrollY = window.scrollY;

      lastScrollY < currentScrollY
        ? setScrollDirection('down')
        : setScrollDirection('up');

      lastScrollY = currentScrollY;
    };
    window.addEventListener('scroll', fnScroll);
    return () => {
      window.removeEventListener('scroll', fnScroll);
    };
  }, []);

  // useEffect(() => {
  //   console.log('y', scrollY);
  //   console.log('di', scrollDirection);
  // }, [scrollDirection, scrollY]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      // section PC
      '(min-width: 769px)': () => {
        const sectionTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: '.sectionArea',
            start: '200 top',
            end: 'bottom bottom',
            scrub: 2,
            toggleActions: 'play none none reverse',
            invalidateOnRefresh: true,
            // markers: true,
          },
        });

        sectionTimeline
          .addLabel('scroll')
          .to('.wrapper', { top: 0, duration: 0.5 }, 'scroll')
          .to(
            '.section1',
            {
              width: '100vw',
              height: '100vh',
              duration: 1,
              onComplete: () => {
                (textRef.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll'
          )
          .to(
            '.sectionArea .textArea',
            { visibility: 'hidden', duration: 0.1 },
            'scroll+=1'
          )
          .to('.section1', { scale: 1.1, duration: 2 }, 'scroll+=1')
          .to('.section1', { top: '-100vh', duration: 1 }, 'scroll+=3')
          .to(
            '.section2',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef2.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef2.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=3'
          )
          .to('.section2', { scale: 1.1, duration: 2 }, 'scroll+=3.5')
          .to('.section2', { top: '-100vh', duration: 1 }, 'scroll+=5.5')
          .to(
            '.section3',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef3.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef3.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=5.5'
          )
          .to('.section3', { scale: 1.1, duration: 2 }, 'scroll+=6')
          .to('.section3', { top: '-100vh', duration: 1 }, 'scroll+=8')
          .to(
            '.section4',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef4.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef4.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=8'
          )
          .to('.section4', { scale: 1.1, duration: 2 }, 'scroll+=8.5')
          .to('.section4', { top: '-100vh', duration: 1 }, 'scroll+=10.5')
          .to(
            '.section5',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef5.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef5.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=10.5'
          )
          .to('.section5', { scale: 1.1, duration: 2 }, 'scroll+=12.5');
      },
      // section Mobile
      '(max-width: 768px)': () => {
        /**
         * Mobile Section Scroll
         */
        const sectionTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: '.sectionArea',
            start: '250 top',
            end: 'bottom bottom',
            scrub: 2,
            toggleActions: 'play none none reverse',
            invalidateOnRefresh: true,
            // markers: true,
          },
        });

        sectionTimeline
          .addLabel('scroll')
          .to('.wrapper', { top: 0, duration: 1 }, 'scroll+=0')
          .to(
            '.section1',
            {
              top: 0,
              width: '100vw',
              height: '100vh',
              duration: 1,
              onComplete: () => {
                (textRef.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=0'
          )
          .to(
            '.sectionArea .textArea',
            { visibility: 'hidden', duration: 0.1 },
            'scroll+=1'
          )
          .to('.section1', { scale: 1.1, duration: 2 }, 'scroll+=1')
          .to('.section1', { top: '-100vh', duration: 1 }, 'scroll+=3')
          .to(
            '.section2',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef2.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef2.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=3'
          )
          .to('.section2', { scale: 1.1, duration: 2 }, 'scroll+=3.5')
          .to('.section2', { top: '-100vh', duration: 1 }, 'scroll+=5.5')
          .to(
            '.section3',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef3.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef3.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=5.5'
          )
          .to('.section3', { scale: 1.1, duration: 2 }, 'scroll+=6')
          .to('.section3', { top: '-100vh', duration: 1 }, 'scroll+=8')
          .to(
            '.section4',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef4.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef4.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=8'
          )
          .to('.section4', { scale: 1.1, duration: 2 }, 'scroll+=8.5')
          .to('.section4', { top: '-100vh', duration: 1 }, 'scroll+=10.5')
          .to(
            '.section5',
            {
              top: 0,
              duration: 1,
              onComplete: () => {
                (textRef5.current! as HTMLElement).style.opacity = '1';
              },
              onReverseComplete: () => {
                (textRef5.current! as HTMLElement).style.opacity = '0';
              },
            },
            'scroll+=10.5'
          )
          .to('.section5', { scale: 1.1, duration: 2 }, 'scroll+=12.5');
      },
    });

    /**
     * Bottom Grid Images scroll
     */
    const gridTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.bottomArea',
        start: 'top center',
        end: 'bottom bottom',
        // scrub: 1,
        // toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
        // markers: true,
      },
    });
    ScrollTrigger.matchMedia({
      // grid PC
      '(min-width: 769px)': () => {
        gridTimeline
          .addLabel('grid')
          .to(
            '.item1 > img',
            {
              opacity: 1,
              duration: 1,
            },
            'grid'
          )
          .to(
            '.item2 > img',
            {
              opacity: 1,
              duration: 1,
            },
            'grid'
          )
          .to(
            '.item1 > div',
            {
              opacity: 1,
              duration: 1,
            },
            'grid+=0.5'
          )
          .to(
            '.item2 > div',
            {
              opacity: 1,
              duration: 1,
            },
            'grid+=0.5'
          )
          .to(
            '.item3 > img',
            {
              opacity: 1,
              duration: 1,
            },
            'grid+=1'
          )
          .to(
            '.item4 > img',
            {
              opacity: 1,
              duration: 1,
            },
            'grid+=1'
          )
          .to(
            '.item3 > div',
            {
              opacity: 1,
              duration: 1,
            },
            'grid+=1.5'
          )
          .to(
            '.item4 > div',
            {
              opacity: 1,
              duration: 1,
            },
            'grid+=1.5'
          );
      },
      // grid Mobile
      '(max-width: 768px)': () => {
        gridTimeline
          .addLabel('grid')
          .to(
            '.item1 > img',
            {
              opacity: 1,
              duration: 0.5,
            },
            'grid'
          )
          .to(
            '.item1 > div',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=0.3'
          )
          .to(
            '.item2 > img',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=0.6'
          )
          .to(
            '.item2 > div',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=0.9'
          )
          .to(
            '.item3 > img',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=1.2'
          )
          .to(
            '.item3 > div',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=1.5'
          )
          .to(
            '.item4 > img',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=1.8'
          )
          .to(
            '.item4 > div',
            {
              opacity: 1,
              duration: 0.3,
            },
            'grid+=2.1'
          );
      },
    });

    window.addEventListener('resize', ScrollTrigger.update);
    return () => {
      window.removeEventListener('resize', ScrollTrigger.update);
    };
  }, []);

  const moveScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.bannerBox}>
        <div
          className={`${style.top} ${scrollY <= 0 ? '' : style.active} ${
            scrollY > 0 && scrollDirection === 'down' ? style.hide : ''
          }`}
        >
          {scrollY <= 0 ? (
            <img
              className={`${style.logo} ${style.cursor}`}
              src="/icons/canofy_logo_horizon.svg"
              onClick={moveScrollToTop}
            />
          ) : (
            <img
              className={`${style.logo} ${style.cursor}`}
              src="/icons/canofy_logo_horizon_black.svg"
              onClick={moveScrollToTop}
            />
          )}
        </div>
        <div className={style.bannerTextArea}>
          <p className={style.title}>care now, fit yourself</p>
          <p className={style.desc}>
            좋아하는 것을 더 오래 할 수 있도록, 좋아하는 사람과 더 오래 살 수
            있도록
          </p>
        </div>
      </div>

      <div className={`${style.main} main`}>
        <div className={`${style.sectionArea} sectionArea`}>
          <div
            className={`${style.textArea} ${
              sectionTextInActive ? style.inactive : ''
            } textArea`}
          >
            <div className={style.title}>We make your life more Vibrant</div>
            <div className={style.desc}>
              <p>
                <span>숨 쉬는 것처럼 당연했던 건강이 </span>
                {isMobile && <br />}
                <span>당연하지 않아질 때 건강은 </span>
                {isMobile && <br />}
                한없이 무거운 불안함이 됩니다.
              </p>
              <p>
                <span>불안함이 당신의 삶을 흔들지 못하도록, </span>
                {isMobile && <br />}
                <span>당신의 내일이 오늘처럼 빛나도록</span>
              </p>
              <p>
                <span>관리와 일상의 경계를 허물고 </span>
                {isMobile && <br />}
                <span>캐노피 아래 펼쳐질 </span>
                {isMobile && <br />}
                <span>당연한 건강을 선사하고자 합니다.</span>
              </p>
            </div>
          </div>
          <div className={`${style.stickyImageArea} wrapper`}>
            <div
              className={`${style.initialImageBox} ${style.section1} section1`}
            >
              <div className={style.sectionTextArea} ref={textRef}>
                <p className={style.title}>
                  <span>오늘의 즐거움이 </span>
                  {isMobile && <br />}
                  내일도 계속되게
                </p>
                <p className={style.desc}>
                  <span>시간이 지나도 여전한 일상을 </span>
                  {isMobile && <br />}
                  <span>유지할 수 있도록 내일의 </span>
                  {isMobile && <br />}
                  <span>건강을 예측하세요.</span>
                </p>
              </div>
            </div>
            <div className={`${style.fullImageBox} ${style.section2} section2`}>
              <div className={style.sectionTextArea} ref={textRef2}>
                <p className={style.title}>
                  몸과 마음의 균형을 {isMobile && <br />}유지할 수 있게
                </p>
                <p className={style.desc}>
                  <span>마음 가는대로 몸이 따를 수 있도록 </span>
                  {isMobile && <br />}
                  <span>숨 쉬듯 자연스럽게 관리하세요.</span>
                </p>
              </div>
            </div>
            <div className={`${style.fullImageBox} ${style.section3} section3`}>
              <div className={style.sectionTextArea} ref={textRef3}>
                <p className={style.title}>
                  좋아하는 음식을 {isMobile && <br />}마음껏 즐기며
                </p>
                <p className={style.desc}>
                  <span>오늘의 소소한 일상을 </span>
                  {isMobile && <br />}
                  <span>걱정없이 만끽하세요.</span>
                </p>
              </div>
            </div>
            <div className={`${style.fullImageBox} ${style.section4} section4`}>
              <div className={style.sectionTextArea} ref={textRef4}>
                <p className={style.title}>
                  하고 싶은 것들을 {isMobile && <br />}신나게 누리며
                </p>
                <p className={style.desc}>
                  <span>앞으로도 변함없이 활기 넘칠 </span>
                  {isMobile && <br />}
                  <span>당신의 즐거움을 지속하세요.</span>
                </p>
              </div>
            </div>
            <div className={`${style.fullImageBox} ${style.section5} section5`}>
              <div className={style.sectionTextArea} ref={textRef5}>
                <p className={style.title}>
                  <span></span>
                  사랑하는 사람과 {isMobile && <br />}함께 오래오래
                </p>
                <p className={style.desc}>
                  <span>당신의 소중한 오늘을 </span>
                  {isMobile && <br />}
                  <span>지켜주는 맞춤 캐노피</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${style.bottomArea} bottomArea`}>
        <div className={style.textArea}>
          <h1>이제는 캐노피가</h1>
          <h1>당신의 일상이 됩니다.</h1>
        </div>
        <div className={style.gridArea}>
          <div className={`${style.item} item1`}>
            <img src="/images/home/bottom1.png" />
            <div className={style.textArea}>
              <div className={style.title}>매일의 일상 속에서 </div>
              <div className={style.desc}>
                <p>일상에 자연스럽게 녹아든 라이프케어로</p>
                <p>특정한 순간만이 아닌 지속되는 건강 인프라를 구축합니다. </p>
              </div>
            </div>
          </div>
          <div className={`${style.item} item2`}>
            <img src="/images/home/bottom2.png" />
            <div className={style.textArea}>
              <div className={style.title}>가장 새로운 기술을 </div>
              <div className={style.desc}>
                <p>첨단 AI 기술이 제공하는 가장 발전된 시스템으로</p>
                <p>불확실함을 예측 가능함으로 바꿔갑니다.</p>
              </div>
            </div>
          </div>
          <div className={`${style.item} item3`}>
            <img src="/images/home/bottom3.png" />
            <div className={style.textArea}>
              <div className={style.title}>누구나 쉽고 익숙하게</div>
              <div className={style.desc}>
                <p>불편함 때문에 소외되는 사람이 없도록</p>
                <p>누구나 사용할 수 있는 쉽고 편리한 서비스를 제공합니다.</p>
              </div>
            </div>
          </div>
          <div className={`${style.item} item4`}>
            <img src="/images/home/bottom4.png" />
            <div className={style.textArea}>
              <div className={style.title}>마음 편히 믿을 수 있게</div>
              <div className={style.desc}>
                <p>10년 이상 축적된 헬스케어 산업의 전문성을 바탕으로</p>
                <p>믿을 수 있는 개인별 맞춤 라이프케어를 제안합니다. </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.videoArea}>
        <video
          src="/video/composition.mp4"
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div className={style.textArea}>
          <div className={style.title}>
            <p>모두가 나만의</p>
            <p>캐노피를 갖는 그날까지</p>
          </div>
          <div className={style.desc}>
            <p>
              좋아하는 것을 더 오래 할 수 있도록,
              {isMobile && <br />}
              좋아하는 사람과 더 오래 살 수 있도록
            </p>
          </div>
        </div>
      </div>

      <div className={style.footerArea}>
        <div className={style.content}>
          {isPC && (
            <>
              <div style={{ paddingRight: '56px' }}>
                <img
                  src="/icons/canofy_logo_horizon_white.svg"
                  className={style.cursor}
                  onClick={moveScrollToTop}
                />
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                  }}
                >
                  <>
                    <div className={style.row}>
                      <span>대표이사 : 최광수</span>
                      <span>사업자등록번호 : 120-87-88602</span>
                      <span>통신판매업신고번호 : 2013-서울강남-01316</span>
                    </div>
                    <div className={style.row}>
                      <span>대표번호 : 1661-2858</span>
                      <span>팩스 : 02-2038-2858</span>
                      <span>이메일 : hello@canofy.co</span>
                    </div>
                    <div className={style.row}>
                      <span>서울특별시 강남구 삼성로95길 27 (삼성동, 영창빌딩 3층)</span>
                    </div>
                  </>
                </div>
                <div className={style.copyright}>
                  LifeSemantics © 2024 LifeSemantics Corp. All rights reserved,
                  except for those photos acknowledged by other photographers.
                </div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <img
                  src="/icons/instagram.svg"
                  className={style.cursor}
                  onClick={() => {
                    window.location.href =
                      'https://www.instagram.com/canofy.official';
                  }}
                />
              </div>
            </>
          )}
          {!isPC && (
            <>
              <div style={{ paddingRight: '56px' }}>
                <img
                  src="/icons/canofy_logo_horizon_white.svg"
                  width="94px"
                  className={style.cursor}
                  onClick={moveScrollToTop}
                />
              </div>
              <div style={{ marginTop: '32px' }}>
                <div className={style.row}>
                  <span>대표이사 : 최광수</span>
                  <span>사업자등록번호 : 120-87-88602</span>
                  <span>통신판매업신고번호 : 2013-서울강남-01316</span>
                  <span>대표번호 : 1661-2858</span>
                  <span>팩스 : 02-2038-2858</span>
                  <span>이메일 : hello@canofy.co</span>
                  <span>서울특별시 강남구 삼성로95길 27 (삼성동, 영창빌딩 3층)</span>
                </div>
              </div>
              <div style={{ margin: '28px 0' }}>
                <img
                  src="/icons/instagram.svg"
                  width="36px"
                  className={style.cursor}
                  onClick={() => {
                    window.location.href =
                      'https://www.instagram.com/canofy.official';
                  }}
                />
              </div>
              <div>
                <div className={style.copyright}>
                  LifeSemantics © 2024 LifeSemantics Corp. All rights reserved,
                  except for those photos acknowledged by other photographers.
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Landing;
