import {
  CustomParams,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';

type PageParam = {
  page_title?: string;
  page_location?: string;
  page_path?: string;
  shareCode?: string;
};

/**
 * GA log
 * @param eventName
 */
const useAnalyticsLogEvent = () => {
  const analytics = getAnalytics();

  const events = (
    eventName: string,
    eventParams?: {
      [key: string]: any;
    }
  ) => {
    logEvent(analytics, eventName, eventParams);
  };

  const pages = (pageParam: PageParam) => {
    logEvent(analytics, 'page_view', pageParam);
  };

  const users = (userId: string) => {
    setUserId(analytics, userId);
  };

  const userProperties = (data: CustomParams) => {
    setUserProperties(analytics, data);
  };

  return { pages, events, users, userProperties };
};

export default useAnalyticsLogEvent;
